<template>
	<div>
		<div>
			<div class="top" v-if="windowWidth>=1200">
				<div class="top_div">
					<!-- logo -->
					<div>
						<img class="top_div_logo" src="@/assets/img/logo3.png" alt="" @click="tabsClick(0)" />
					</div>
					
					<!-- tabs列表 -->
					<div class="top_div_tabs">
						<div style="cursor: pointer;position: relative;" v-for="(item,index) in $t('tabList')" :key="index"
							@mousemove="tabsMoveEvent($event,index)" @mouseleave="tabsMouseEvent($event,index)"
							@click="tabsClick(index)">
							<div class="text" >{{item.name}}</div>
							<div :class="tabsIndex==index ? 'active' : ''"></div>
						</div>
						
						<div style="height: 100%;display: flex;align-items: center;padding-left: 20px;text-align: right;">
							<div>
								<div class="i18nText" @click="i18nClick">{{language}}</div>
								<div class="i18nPhone">+86 0755-82056199</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="top" v-if="windowWidth<=1199">
				<div class="top_div">
					<!-- logo -->
					<div>
						<img class="top_div_logo" src="@/assets/img/logo3.png" alt="" @click="tabsClick(0)" />
						
					</div>
					
					<div class="top_div_rightIcon" @click="drawerClick">
						<el-image class="rightIcon" :src="require('@/assets/img/more2.png')" fit="cover"></el-image>
					</div>
				</div>
			</div>
			
			<el-drawer
			  title=""
			  size="80%"
			  :visible.sync="drawer"
			  :with-header="false">
			  <div class="drawer">
				  <div style="text-align: center;">
					 <div class="dr_tabs" v-for="(item,index) in $t('tabList')" :key="index" @click="tabsClick(index)">
						<div class="dr_tabs_name">{{item.name}}</div>
						<div class="dr_tabs_icon">></div>
					 </div>
					 <div class="dr_tabs_i18n" @click="i18nClick">{{language}}</div>
				  </div>
				  <div class="dr_phone">+86 0755-82056199</div>
			  </div>
			</el-drawer>

		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				tabsTwoList: [{
					id: 1,
					name: 'PCB'
				}, {
					id: 2,
					name: 'PCBA'
				}],

				tabsIndex: null, //tabs下标
				tabsTextIndex: null, //tabs字下标
				tabsTwoIndex: 0,
				tabsTakeNotes: null, //记录下标
				tabsTwoType: false, //tabs产品弹框状态
				searchType: false, //搜索弹框状态
				language: 'EN', //中英文切换
				langDrop: false, //是否打开中英文弹框
				langAction: null, //语言弹框下标
				route:'',//route路由
				drawer: false,//是否打开弹框
			}
		},

		created() {
			// console.log('1111',this.tabsIndex)
			this.tabsTakeNotes = this.$store.state.tabsIndex;
			this.$i18n.locale = this.$store.state.language;
			if (this.$i18n.locale == 'cn') {
				this.language = 'EN'
			} else {
				this.language = 'CN'
			}
			this.route=this.$route.path;
		},

		mounted() {
			
		},

		watch: {
			//双向绑定语言切换
			'$i18n.locale'(val) {
				if (this.$i18n.locale == 'cn') {
					this.language = 'EN';
					this.$store.dispatch("setLanguage", {
					    language: 'cn',
					});
				} else {
					this.language = 'CN';
					this.$store.dispatch("setLanguage", {
					    language: 'en',
					});
				}
				this.route=this.$route.path;
			}
		},

		computed: {
		},

		methods: {
			//-------------------移入tabs事件-----------------------------
			tabsMoveEvent: function(event, index) {
				this.tabsIndex = index;
				if (this.tabsIndex == 1) {
					this.tabsTwoType = true
					if (this.searchType == true) {
						this.searchType = false;
					}
				}else{
					this.tabsTwoType = false
				}
			},
			
			//------------------tabs跳转---------------------------
			tabsSwitch:function(index){
				console.log('top',index)
				this.$store.dispatch("setTabsIndex", {
					tabsIndex: 1,
				});//导航下标vuex
				sessionStorage.setItem("setTabsIndex",1);//导航下标缓存
				if(this.$route.path!=='/productList'){
					this.$store.dispatch("setProductClass", {
					    productClass: index,
					});
					this.$router.push({
						path: '/productList',
					})
				}else {
					this.$store.dispatch("setProductClass", {
					    productClass: index,
					});
				}
			},
			
			//---------------------语言切换--------------------------
			i18nClick:function(){
				this.drawer=false;
				if(this.$i18n.locale == 'cn'){
					this.$i18n.locale = 'en';
					this.$store.dispatch("setLanguage", {
					    language: 'en',
					});
					console.log(this.$store.state.language)
				}else{
					this.$i18n.locale = 'cn';
					this.$store.dispatch("setLanguage", {
					    language: 'cn',
					});
					console.log(this.$store.state.language)
				}
			},

			//------------------移出tabs事件--------------------------------
			tabsMouseEvent: function(event, index) {
				this.tabsIndex = null;
			},

			//-------------------移入tabsTwo事件-----------------------------
			tabsTwoMoveEvent: function(event, index) {
				this.tabsTwoIndex = index;
			},

			//-----------------移出tabsTwo事件---------------------------
			tabsTwoMouseEvent: function(event, index) {
				this.tabsIndex = null;
				this.tabsTwoType = false;
			},

			//-----------------移入搜索事件--------------------------
			searchClick: function() {
				if (this.searchType == false) {
					this.searchType = true;
				} else {
					this.searchType = false;
				}
			},

			//-----------选择语言弹框移入事件-------------
			langMoveEvent: function(event, index) {
				this.langAction = index
			},

			//-----------选择语言弹框移出事件-------------
			langLeveEvent: function(event, index) {
				this.langAction = null
				if (index == 3 && this.langDrop == true) {
					setTimeout(() => {
						this.langDrop = false
					}, 500)
				}
			},
			
			//----------选择语言------------------------
			langClick: function(e) {
				if (e == 0) {
					this.language = 'CN'
					this.langDrop = false
					this.$i18n.locale = 'cn';
				} else {
					this.language = 'EN'
					this.langDrop = false
					this.$i18n.locale = 'en';
				}
			},

			//------------tabs点击事件----------------
			tabsClick: function(index) {
				// this.tabsIndex = index
				this.drawer=false;
				switch (index) {
					case 0:
						if(this.route=='/'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/',
								query:{
									selector:null
								}
							})
						}
						break;
					case 1:
						if(this.route=='/aboutUs'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/aboutUs',
							})
						}
						break;
					case 2:
						if(this.route=='/productCenter'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/productCenter',
							})
						}
						break;
					case 3:
						if(this.route=='/serviceItems'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/serviceItems',
							})
						}
						break;
					case 4:
						if(this.route=='/news'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/news',
							})
						}
						break;
					case 5:
						if(this.route=='/humanResources'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/humanResources',
							})
						}
						break;
					case 6:
						if(this.route=='/contactUs'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/contactUs',
							})
						}
						break;
					default:
						break;
				}
			},
			
			//---------------------弹框打开-------------------------
			drawerClick:function(){
				this.drawer=true;
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.top {
			z-index: 200;
			position: fixed;
			top: 0;
			width: 100%;
			box-shadow: 0px 0px 2px rgb(0 0 0 / 30%);
		
			.top_div {
				position: relative;
				background: #fff;
				display: flex;
				padding: 0 150px;
		
				.top_div_logo {
					margin: 16px 0;
					width: 320px;
					height: 60px;
				}
		
				.top_div_tabs {
					display: flex;
					align-items: center;
					justify-content: right;
					width: 100%;
		
					.text {
						font-size: 18px;
						font-weight: bold;
						padding: 20px;
					}
					
					.text:hover{
						color: #1074bc;
					}
		
					.active {
						width: 100%;
						height: 2px;
						position: absolute;
						// left: 50%;
						bottom: -16px;
						transform: translateX(0%);
						transition: all .4s ease;
						background: #1074bc;
					}
				}
		
				.top_div_tabsFrame {
					background: rgba(255, 255, 255, 0.6);
					padding: 30px 60px;
					position: absolute;
					top: 134.6px;
					width: 100%;
					left: 0;
					display: flex;
					justify-content: center;
					backdrop-filter: blur(5px);
					animation: tabsFrame .6s;
		
					@keyframes tabsFrame {
						// 0% {
						// 	opacity: 0;
						// }
		
						// 100% {
						// 	opacity: 1;
						// }
						0% {
							transform: translateY(-60%);
						}
		
						100% {
							transform: translateY(0);
						}
					}
				}
			}
		}
		
		.i18nText{
			cursor: pointer;
			padding-bottom: 10px;
			transition: all .4s ease;
		}
		
		.i18nText:hover{
			color: #1074bc;
		}
		
		.searchIcon{
			display: flex;
			justify-content: center;
			align-items: center;
			padding-left: 20px;
			
			.image{
				width: 25px;
				margin: 5px 10px;
			}
		}
		
		
		.search {
			position: absolute;
			top: 134.6px;
			width: 100%;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 20px;
		
			.search_div {
				padding: 20px;
				background: rgba(255, 255, 255, 0.6);
				border-radius: 5px;
				backdrop-filter: blur(5px);
				display: flex;
				justify-content: center;
				align-items: center;
				animation: inputFrame .6s;
		
				.sr_div_input {
					width: 500px;
					// padding: 20px;
					outline: none;
					border: none;
					background: none;
					border-radius: 8px;
					// background: rgba(255, 255, 255, 0.6);
					// backdrop-filter: blur(5px);
					font-size: 16px;
				}
		
				@keyframes inputFrame {
					// 0% {
					// 	opacity: 0;
					// }
		
					// 100% {
					// 	opacity: 1;
					// }
					0% {
						transform: translateY(-60%);
					}
		
					100% {
						transform: translateY(0);
					}
				}
			}
		}
		
		.frame_content {
			border-radius: 4px;
			width: 100%;
			text-align: center;
		
			.text {
				color: #000;
				padding: 10px 20px;
				cursor: pointer;
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.top {
			z-index: 200;
			position: fixed;
			top: 0;
			width: 100%;
			box-shadow: 0px 0px 2px rgb(0 0 0 / 30%);
		
			.top_div {
				position: relative;
				background: #fff;
				display: flex;
				padding: 0 150px;
		
				.top_div_logo {
					margin: 16px 0;
					width: 320px;
					height: 60px;
				}
		
				.top_div_tabs {
					display: flex;
					align-items: center;
					justify-content: right;
					width: 100%;
		
					.text {
						font-size: 18px;
						font-weight: bold;
						padding: 20px;
					}
					
					.text:hover{
						color: #1074bc;
					}
		
					.active {
						width: 100%;
						height: 2px;
						position: absolute;
						left: 50%;
						bottom: -17px;
						transform: translateX(-50%);
						transition: all .4s ease;
						background: #1074bc;
					}
				}
		
				.top_div_tabsFrame {
					background: rgba(255, 255, 255, 0.6);
					padding: 30px 60px;
					position: absolute;
					top: 134.6px;
					width: 100%;
					left: 0;
					display: flex;
					justify-content: center;
					backdrop-filter: blur(5px);
					animation: tabsFrame .6s;
		
					@keyframes tabsFrame {
						// 0% {
						// 	opacity: 0;
						// }
		
						// 100% {
						// 	opacity: 1;
						// }
						0% {
							transform: translateY(-60%);
						}
		
						100% {
							transform: translateY(0);
						}
					}
				}
			}
		}
		
		.i18nText{
			cursor: pointer;
			padding-bottom: 10px;
			transition: all .4s ease;
		}
		
		.i18nText:hover{
			color: #1074bc;
		}
		
		.searchIcon{
			display: flex;
			justify-content: center;
			align-items: center;
			padding-left: 20px;
			
			.image{
				width: 25px;
				margin: 5px 10px;
			}
		}
		
		.search {
			position: absolute;
			top: 134.6px;
			width: 100%;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 20px;
		
			.search_div {
				padding: 20px;
				background: rgba(255, 255, 255, 0.6);
				border-radius: 5px;
				backdrop-filter: blur(5px);
				display: flex;
				justify-content: center;
				align-items: center;
				animation: inputFrame .6s;
		
				.sr_div_input {
					width: 500px;
					// padding: 20px;
					outline: none;
					border: none;
					background: none;
					border-radius: 8px;
					// background: rgba(255, 255, 255, 0.6);
					// backdrop-filter: blur(5px);
					font-size: 16px;
				}
		
				@keyframes inputFrame {
					// 0% {
					// 	opacity: 0;
					// }
		
					// 100% {
					// 	opacity: 1;
					// }
					0% {
						transform: translateY(-60%);
					}
		
					100% {
						transform: translateY(0);
					}
				}
			}
		}
		
		.frame_content {
			border-radius: 4px;
			width: 100%;
			text-align: center;
		
			.text {
				color: #000;
				padding: 10px 20px;
				cursor: pointer;
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.top {
			z-index: 200;
			position: fixed;
			top: 0;
			width: 100%;
			box-shadow: 0px 0px 2px rgb(0 0 0 / 30%);
		
			.top_div {
				position: relative;
				background: #fff;
				display: flex;
				padding: 0 100px;
		
				.top_div_logo {
					margin: 16px 0;
					width: 280px;
					height: 50px;
				}
		
				.top_div_tabs {
					display: flex;
					align-items: center;
					justify-content: right;
					width: 100%;
		
					.text {
						font-size: 18px;
						font-weight: bold;
						padding: 20px;
					}
					
					.text:hover{
						color: #1074bc;
					}
		
					.active {
						width: 100%;
						height: 2px;
						position: absolute;
						left: 50%;
						bottom: -12px;
						transform: translateX(-50%);
						transition: all .4s ease;
						background: #1074bc;
					}
				}
		
				.top_div_tabsFrame {
					background: rgba(255, 255, 255, 0.6);
					padding: 30px 60px;
					position: absolute;
					top: 134.6px;
					width: 100%;
					left: 0;
					display: flex;
					justify-content: center;
					backdrop-filter: blur(5px);
					animation: tabsFrame .6s;
		
					@keyframes tabsFrame {
						// 0% {
						// 	opacity: 0;
						// }
		
						// 100% {
						// 	opacity: 1;
						// }
						0% {
							transform: translateY(-60%);
						}
		
						100% {
							transform: translateY(0);
						}
					}
				}
			}
		}
		
		.i18nText{
			font-size: 12px;
			cursor: pointer;
			padding-bottom: 10px;
			transition: all .4s ease;
		}
		
		.i18nText:hover{
			color: #1074bc;
		}
		
		.i18nPhone{
			font-size: 12px;
		}
		
		.searchIcon{
			display: flex;
			justify-content: center;
			align-items: center;
			padding-left: 20px;
			
			.image{
				width: 25px;
				margin: 5px 10px;
			}
		}
		
		.search {
			position: absolute;
			top: 134.6px;
			width: 100%;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 20px;
		
			.search_div {
				padding: 20px;
				background: rgba(255, 255, 255, 0.6);
				border-radius: 5px;
				backdrop-filter: blur(5px);
				display: flex;
				justify-content: center;
				align-items: center;
				animation: inputFrame .6s;
		
				.sr_div_input {
					width: 500px;
					// padding: 20px;
					outline: none;
					border: none;
					background: none;
					border-radius: 8px;
					// background: rgba(255, 255, 255, 0.6);
					// backdrop-filter: blur(5px);
					font-size: 16px;
				}
		
				@keyframes inputFrame {
					// 0% {
					// 	opacity: 0;
					// }
		
					// 100% {
					// 	opacity: 1;
					// }
					0% {
						transform: translateY(-60%);
					}
		
					100% {
						transform: translateY(0);
					}
				}
			}
		}
		
		.frame_content {
			border-radius: 4px;
			width: 100%;
			text-align: center;
		
			.text {
				color: #000;
				padding: 10px 20px;
				cursor: pointer;
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.top {
			z-index: 200;
			position: fixed;
			top: 0;
			width: 100%;
			box-shadow: 0px 0px 2px rgb(0 0 0 / 30%);
		
			.top_div {
				position: relative;
				background: #fff;
				display: flex;
				padding: 0 60px;
		
				.top_div_logo {
					position: relative;
					display: flex;
					align-items: center;
					width: 140px;
					height: 30px;
				}
		
				.top_div_tabs {
					display: flex;
					align-items: center;
					justify-content: right;
					width: 100%;
		
					.text {
						font-size: 18px;
						font-weight: bold;
						padding: 20px;
					}
					
					.text:hover{
						color: #1074bc;
					}
		
					.active {
						width: 100%;
						height: 2px;
						position: absolute;
						left: 50%;
						bottom: -8px;
						transform: translateX(-50%);
						transition: all .4s ease;
						background: #1074bc;
					}
				}
		
				.top_div_tabsFrame {
					background: rgba(255, 255, 255, 0.6);
					padding: 30px 60px;
					position: absolute;
					top: 134.6px;
					width: 100%;
					left: 0;
					display: flex;
					justify-content: center;
					backdrop-filter: blur(5px);
					animation: tabsFrame .6s;
		
					@keyframes tabsFrame {
						// 0% {
						// 	opacity: 0;
						// }
		
						// 100% {
						// 	opacity: 1;
						// }
						0% {
							transform: translateY(-60%);
						}
		
						100% {
							transform: translateY(0);
						}
					}
				}
			}
		}
		
		.i18nText{
			font-size: 10px;
			cursor: pointer;
			padding-bottom: 10px;
			transition: all .4s ease;
		}
		
		.i18nText:hover{
			color: #1074bc;
		}
		
		.i18nPhone{
			font-size: 10px;
		}
		
		.searchIcon{
			display: flex;
			justify-content: center;
			align-items: center;
			padding-left: 20px;
			
			.image{
				width: 18px;
				margin: 5px 10px;
			}
		}
		
		.search {
			position: absolute;
			top: 134.6px;
			width: 100%;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 20px;
		
			.search_div {
				padding: 20px;
				background: rgba(255, 255, 255, 0.6);
				border-radius: 5px;
				backdrop-filter: blur(5px);
				display: flex;
				justify-content: center;
				align-items: center;
				animation: inputFrame .6s;
		
				.sr_div_input {
					width: 500px;
					// padding: 20px;
					outline: none;
					border: none;
					background: none;
					border-radius: 8px;
					// background: rgba(255, 255, 255, 0.6);
					// backdrop-filter: blur(5px);
					font-size: 16px;
				}
		
				@keyframes inputFrame {
					// 0% {
					// 	opacity: 0;
					// }
		
					// 100% {
					// 	opacity: 1;
					// }
					0% {
						transform: translateY(-60%);
					}
		
					100% {
						transform: translateY(0);
					}
				}
			}
		}
		
		.frame_content {
			border-radius: 4px;
			width: 100%;
			text-align: center;
			font-size: 14px;
		
			.text {
				color: #000;
				padding: 10px 20px;
				cursor: pointer;
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.top{
			z-index: 200;
			position: fixed;
			top: 0;
			width: 100%;
			box-shadow: 0px 0px 2px rgb(0 0 0 / 30%);
			
			.top_div{
				position: relative;
				background: #fff;
				display: flex;
				padding: 0 60px;
				
				.top_div_logo {
					margin: 16px 0;
					width: 150px;
					height: 24px;
				}
				
				.top_div_rightIcon{
					position: relative;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: right;
					
					.rightIcon{
						width: 40px;
						height: 24px;
					}
				}
			}
		}
		
		.drawer{
			padding: 60px 40px;
			position: relative;
			
			.dr_tabs{
				border-bottom: 1px solid #f5f5f5;
				position: relative;
				
				.dr_tabs_name{
					font-size: 23px;
					padding: 40px;
					color: #333;
				}
				
				.dr_tabs_icon{
					position: absolute;
					top: 0;
					right: 0;
					font-size: 23px;
					height: 100%;
					display: flex;
					align-items: center;
				}
			}
			
			.dr_phone{
				position: fixed;
				bottom: 0;
				padding: 20px;
				width: 73%;
				text-align: center;
			}
			
			.dr_tabs_i18n{
				border-bottom: 1px solid #f5f5f5;
				position: relative;
				font-size: 23px;
				padding: 40px;
				color: #1074bc;
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.top{
			z-index: 200;
			position: fixed;
			top: 0;
			width: 100%;
			box-shadow: 0px 0px 2px rgb(0 0 0 / 30%);
			
			.top_div{
				position: relative;
				background: #fff;
				display: flex;
				padding: 0 30px;
				
				.top_div_logo {
					margin: 16px 0;
					width: 150px;
					height: 24px;
				}
				
				.top_div_rightIcon{
					position: relative;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: right;
					
					.rightIcon{
						width: 40px;
						height: 24px;
					}
				}
			}
		}
		
		.drawer{
			padding: 60px 40px;
			position: relative;
			
			.dr_tabs{
				border-bottom: 1px solid #f5f5f5;
				position: relative;
				
				.dr_tabs_name{
					font-size: 16px;
					padding: 20px;
					color: #333;
				}
				
				.dr_tabs_icon{
					position: absolute;
					top: 0;
					right: 0;
					font-size: 16px;
					height: 100%;
					display: flex;
					align-items: center;
				}
			}
			
			.dr_phone{
				position: fixed;
				bottom: 0;
				padding: 20px;
				width: 60%;
				text-align: center;
			}
			
			.dr_tabs_i18n{
				border-bottom: 1px solid #f5f5f5;
				position: relative;
				font-size: 16px;
				padding: 20px;
				color: #1074bc;
			}
		}
	}
</style>
