<template>
	<div>
		<div class="bottom" :style="'background-image:' + `url(${require('@/assets/img/advantageDistribution.png')})`">
			<div class="bottom_div">
				<div class="dt_div_left">
					<div>
						<a class="dt_div_left_title" @click="goAnchor('home')">{{$t('bottom.class7.title')}}</a>
					
						<a class="dt_div_left_title2" @click="goAnchor('#about')">{{$t('bottom.class7.text1')}}</a>
						<a class="dt_div_left_title2" @click="goAnchor('#advantageDistribution')">{{$t('bottom.class7.text2')}}</a>
						<a class="dt_div_left_title2" @click="goAnchor('#qualityAssurance')">{{$t('bottom.class7.text3')}}</a>
						<a class="dt_div_left_title2" @click="goAnchor('#serviceItems')">{{$t('bottom.class7.text4')}}</a>
						<a class="dt_div_left_title2" @click="goAnchor('#news')">{{$t('bottom.class7.text5')}}</a>
					</div>
					<div style="padding-left: 60px;">
						<a class="dt_div_left_title" @click="goAnchor('aboutUs')">{{$t('bottom.class1.title')}}</a>

						<a class="dt_div_left_title2" @click="goAnchor('#aboutUs')">{{$t('bottom.class1.text1')}}</a>
						<a class="dt_div_left_title2" @click="goAnchor('#developmentHistory')">{{$t('bottom.class1.text2')}}</a>
						<a class="dt_div_left_title2" @click="goAnchor('#qualificationsHonors')">{{$t('bottom.class1.text3')}}</a>
						<a class="dt_div_left_title2" @click="goAnchor('#coreConcepts')">{{$t('bottom.class1.text4')}}</a>
					</div>

					<div style="padding-left: 60px;">
						<a class="dt_div_left_title" @click="goAnchor('productCenter')">{{$t('bottom.class2.title')}}</a>

						<a class="dt_div_left_title2" @click="goAnchor('#agentBrand')">{{$t('bottom.class2.text1')}}</a>
						<a class="dt_div_left_title2" @click="goAnchor('#advantageDistribution2')">{{$t('bottom.class2.text2')}}</a>
					</div>

					<div style="padding-left: 60px;">
						<a class="dt_div_left_title" @click="goAnchor('serviceItems')">{{$t('bottom.class3.title')}}</a>

						<a class="dt_div_left_title2" @click="goAnchor('serviceItems2')">{{$t('bottom.class3.title')}}</a>
						<a class="dt_div_left_title2" @click="goAnchor('QualityManagementSystem')">{{$t('bottom.class3.text1')}}</a>
						<a class="dt_div_left_title2" @click="goAnchor('ServiceTrade')">{{$t('bottom.class3.text2')}}</a>
						<a class="dt_div_left_title2" @click="goAnchor('ProductCategory')">{{$t('bottom.class3.text3')}}</a>
						<!-- <a class="dt_div_left_title2">在线订单</a> -->
					</div>

					<div style="padding-left: 60px;">
						<a class="dt_div_left_title" @click="goAnchor('News')">{{$t('bottom.class4.title')}}</a>
					</div>

					<div style="padding-left: 60px;">
						<a class="dt_div_left_title" @click="goAnchor('humanResources')">{{$t('bottom.class5.title')}}</a>

						<!-- <a class="dt_div_left_title2">人才理念</a> -->
						<!-- <a class="dt_div_left_title2">{{$t('bottom.class5.text1')}}</a> -->
					</div>

					<div style="padding-left: 60px;">
						<a class="dt_div_left_title" @click="goAnchor('ContactUs')">{{$t('bottom.class6.title')}}</a>

						<!-- <a class="dt_div_left_title2">{{$t('bottom.class6.text1')}}</a> -->
					</div>
				</div>
				<div style="width: 15%;position: relative;display: flex;justify-content: right;">
					<div>
						<div>
							<el-image style="width: 150px;height: 150px;" :src="require('@/assets/img/QRCode.jpg')"></el-image>
						</div>

						<!-- <div class="btn wow fadeInUp">
							<a class="learnMore"></a>
							<a class="learnMore2">探索更多</a>
						</div> -->
					</div>
				</div>
			</div>
			
			<!--  底部  -->
			<div style="text-align: center;padding-top: 20px;">
				<span style="font-size: 10px;color: #fff;">深圳市芯航国际电子有限公司版权所有 <a style="font-size: 10px;padding-left: 10px;color: #fff;" href="https://beian.miit.gov.cn/">【粤ICP备2021032500号】【粤ICP备2021032500号-2】【粤ICP备2021032500号-3】</a></span>
			</div>
		</div>
	</div>
</template>

<script>
	import { scrollTo } from '@/utils/scroll-to'
	export default {
		data() {
			return {
				route:'',//route路由
			}
		},

		created() {
			this.route=this.$route.path;
			// console.log(this.$route)
		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {
			//---------------锚点跳转----------------
			goAnchor: function(selector) {
				switch (selector) {
					case 'home':
						if(this.route!=='/'){
							this.$router.push({
								path: '/',
							})
						}else{
							scrollTo(0,100)
						}
						break;
					case '#about':
						if(this.route!=='/home'){
							this.$router.push({
								path: '/home',
								query:{
									selector:selector
								}
							})
						}else{
							document.querySelector(selector).scrollIntoView({
								behavior: "smooth"
							})
						}
						break;
					case '#advantageDistribution':
						if(this.route!=='/home'){
							this.$router.push({
								path: '/home',
								query:{
									selector:selector
								}
							})
						}else{
							document.querySelector(selector).scrollIntoView({
								behavior: "smooth"
							})
						}
						break;
					case '#advantageDistribution':
						if(this.route!=='/home'){
							this.$router.push({
								path: '/home',
								query:{
									selector:selector
								}
							})
						}else{
							document.querySelector(selector).scrollIntoView({
								behavior: "smooth"
							})
						}
						break;
					case '#qualityAssurance':
						if(this.route!=='/home'){
							this.$router.push({
								path: '/home',
								query:{
									selector:selector
								}
							})
						}else{
							document.querySelector(selector).scrollIntoView({
								behavior: "smooth"
							})
						}
						break;
					case '#serviceItems':
						if(this.route!=='/home'){
							this.$router.push({
								path: '/home',
								query:{
									selector:selector
								}
							})
						}else{
							document.querySelector(selector).scrollIntoView({
								behavior: "smooth"
							})
						}
						break;
					case '#news':
						if(this.route!=='/home'){
							this.$router.push({
								path: '/home',
								query:{
									selector:selector
								}
							})
						}else{
							document.querySelector(selector).scrollIntoView({
								behavior: "smooth"
							})
						}
						break;
					case 'aboutUs':
						if(this.route!=='/aboutUs'){
							this.$router.push({
								path: '/aboutUs',
								query:{
									selector:selector
								}
							})
						}else{
							scrollTo(0,100)
						}
						break;
					case '#aboutUs':
						if(this.route!=='/aboutUs'){
							this.$router.push({
								path: '/aboutUs',
								query:{
									selector:selector
								}
							})
						}else{
							document.querySelector(selector).scrollIntoView({
								behavior: "smooth"
							})
						}
						break;
					case '#developmentHistory':
						if(this.route!=='/aboutUs'){
							this.$router.push({
								path: '/aboutUs',
								query:{
									selector:selector
								}
							})
						}else{
							document.querySelector(selector).scrollIntoView({
								behavior: "smooth"
							})
						}
						break;
					case '#qualificationsHonors':
						if(this.route!=='/aboutUs'){
							this.$router.push({
								path: '/aboutUs',
								query:{
									selector:selector
								}
							})
						}else{
							document.querySelector(selector).scrollIntoView({
								behavior: "smooth"
							})
						}
						break;
					case '#coreConcepts':
						if(this.route!=='/aboutUs'){
							this.$router.push({
								path: '/aboutUs',
								query:{
									selector:selector
								}
							})
						}else{
							document.querySelector(selector).scrollIntoView({
								behavior: "smooth"
							})
						}
						break;
					case 'productCenter':
						if(this.route!=='/productCenter'){
							this.$router.push({
								path: '/productCenter',
								query:{
									selector:selector
								}
							})
						}else{
							scrollTo(0,100)
						}
						break;
					case '#agentBrand':
						if(this.route!=='/productCenter'){
							this.$router.push({
								path: '/productCenter',
								query:{
									selector:selector
								}
							})
						}else{
							document.querySelector(selector).scrollIntoView({
								behavior: "smooth"
							})
						}
						break;
					case '#advantageDistribution2':
						if(this.route!=='/productCenter'){
							this.$router.push({
								path: '/productCenter',
								query:{
									selector:selector
								}
							})
						}else{
							document.querySelector('#advantageDistribution').scrollIntoView({
								behavior: "smooth"
							})
						}
						break;
					case 'serviceItems':
						if(this.route!=='/serviceItems'){
							this.$router.push({
								path: '/serviceItems',
								query:{
									anchorPointIndex:0
								}
							})
						}else{
							this.$store.dispatch("setAnchorPointIndex", {
							    anchorPointIndex: 0,
							});
							scrollTo(0,100)
						}
						break;
					case 'serviceItems2':
						if(this.route!=='/serviceItems'){
							this.$router.push({
								path: '/serviceItems',
								query:{
									anchorPointIndex:0
								}
							})
						}else{
							this.$store.dispatch("setAnchorPointIndex", {
							    anchorPointIndex: 0,
							});
							scrollTo(0,100)
						}
						break;
					case 'QualityManagementSystem':
						if(this.route!=='/serviceItems'){
							this.$router.push({
								path: '/serviceItems',
								query:{
									anchorPointIndex:1
								}
							})
						}else{
							this.$store.dispatch("setAnchorPointIndex", {
							    anchorPointIndex: 1,
							});
							scrollTo(0,100)
						}
						break;
					case 'ServiceTrade':
						if(this.route!=='/serviceItems'){
							this.$router.push({
								path: '/serviceItems',
								query:{
									anchorPointIndex:2
								}
							})
						}else{
							this.$store.dispatch("setAnchorPointIndex", {
							    anchorPointIndex: 2,
							});
							scrollTo(0,100)
						}
						break;
					case 'ProductCategory':
						if(this.route!=='/serviceItems'){
							this.$router.push({
								path: '/serviceItems',
								query:{
									anchorPointIndex:3
								}
							})
						}else{
							this.$store.dispatch("setAnchorPointIndex", {
							    anchorPointIndex: 3,
							});
							scrollTo(0,100)
						}
						break;
					case 'News':
						if(this.route!=='/news'){
							this.$router.push({
								path: '/news',
							})
						}else{
							scrollTo(0,100)
						}
						break;
					case 'humanResources':
						if(this.route!=='/humanResources'){
							this.$router.push({
								path: '/humanResources',
							})
						}else{
							scrollTo(0,100)
						}
						break;
					case 'ContactUs':
						if(this.route!=='/contactUs'){
							this.$router.push({
								path: '/contactUs',
							})
						}else{
							scrollTo(0,100)
						}
						break;
					default:
						break;
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	@media only screen and (min-width:1920px) {
		.btn {
			margin-top: 20px;
			width: 150px;
			line-height: 45px;
			height: 45px;
			border: 1px solid #fff;
			color: #fff;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
			color: #fff;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.bottom {
			padding: 80px 200px;
		
			.bottom_div {
				display: flex;
				width: 100%;
				position: relative;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				padding-bottom: 40px;
		
				.dt_div_left {
					width: 85%;
					display: flex;
		
					.dt_div_left_title {
						font-size: 18px;
						color: #fff;
						line-height: 32px;
						display: block;
						padding-bottom: 10px;
						position: relative;
						cursor: pointer;
					}
		
					.dt_div_left_title2 {
						font-size: 14px;
						line-height: 30px;
						color: #ccc;
						display: block;
						cursor: pointer;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.btn {
			margin-top: 20px;
			width: 150px;
			line-height: 45px;
			height: 45px;
			border: 1px solid #fff;
			color: #fff;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
			color: #fff;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.bottom {
			padding: 80px 200px;
		
			.bottom_div {
				display: flex;
				width: 100%;
				position: relative;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				padding-bottom: 40px;
		
				.dt_div_left {
					width: 85%;
					display: flex;
		
					.dt_div_left_title {
						font-size: 18px;
						color: #fff;
						line-height: 32px;
						display: block;
						padding-bottom: 10px;
						position: relative;
						cursor: pointer;
					}
		
					.dt_div_left_title2 {
						font-size: 14px;
						line-height: 30px;
						color: #ccc;
						display: block;
						cursor: pointer;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.btn {
			margin-top: 20px;
			width: 150px;
			line-height: 45px;
			height: 45px;
			border: 1px solid #fff;
			color: #fff;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
			color: #fff;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.bottom {
			padding: 80px 100px;
		
			.bottom_div {
				display: flex;
				width: 100%;
				position: relative;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				padding-bottom: 40px;
		
				.dt_div_left {
					width: 85%;
					display: flex;
		
					.dt_div_left_title {
						font-size: 18px;
						color: #fff;
						line-height: 32px;
						display: block;
						padding-bottom: 10px;
						position: relative;
						cursor: pointer;
					}
		
					.dt_div_left_title2 {
						font-size: 14px;
						line-height: 30px;
						color: #ccc;
						display: block;
						cursor: pointer;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.btn {
			margin-top: 20px;
			width: 150px;
			line-height: 45px;
			height: 45px;
			border: 1px solid #fff;
			color: #fff;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
			color: #fff;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.bottom {
			padding: 80px 60px;
		
			.bottom_div {
				display: flex;
				width: 100%;
				position: relative;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				padding-bottom: 40px;
		
				.dt_div_left {
					width: 85%;
					display: flex;
		
					.dt_div_left_title {
						font-size: 18px;
						color: #fff;
						line-height: 32px;
						display: block;
						padding-bottom: 10px;
						position: relative;
						cursor: pointer;
					}
		
					.dt_div_left_title2 {
						font-size: 14px;
						line-height: 30px;
						color: #ccc;
						display: block;
						cursor: pointer;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.btn {
			margin-top: 20px;
			width: 150px;
			line-height: 45px;
			height: 45px;
			border: 1px solid #fff;
			color: #fff;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
			color: #fff;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.bottom {
			padding: 40px;
		
			.bottom_div {
				display: flex;
				width: 100%;
				position: relative;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				padding-bottom: 40px;
		
				.dt_div_left {
					width: 85%;
					display: flex;
		
					.dt_div_left_title {
						font-size: 18px;
						color: #fff;
						line-height: 32px;
						display: block;
						padding-bottom: 10px;
						position: relative;
						cursor: pointer;
					}
		
					.dt_div_left_title2 {
						font-size: 14px;
						line-height: 30px;
						color: #ccc;
						display: block;
						cursor: pointer;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.btn {
			margin-top: 20px;
			width: 150px;
			line-height: 45px;
			height: 45px;
			border: 1px solid #fff;
			color: #fff;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
			color: #fff;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.bottom {
			padding: 20px;
		
			.bottom_div {
				display: flex;
				width: 100%;
				position: relative;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				padding-bottom: 40px;
		
				.dt_div_left {
					width: 85%;
					display: flex;
		
					.dt_div_left_title {
						font-size: 18px;
						color: #fff;
						line-height: 32px;
						display: block;
						padding-bottom: 10px;
						position: relative;
						cursor: pointer;
					}
		
					.dt_div_left_title2 {
						font-size: 14px;
						line-height: 30px;
						color: #ccc;
						display: block;
						cursor: pointer;
					}
				}
			}
		}
	}
	
	.el-login-footer {
		height: 40px;
		line-height: 40px;
		position: fixed;
		bottom: 0;
		width: 100%;
		text-align: center;
		color: #fff;
		font-family: Arial;
		font-size: 12px;
		letter-spacing: 1px;
	}
	
</style>
