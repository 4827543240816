const emailHtml = (e) => {
	let html=`<div style="background: #c6c6c6;padding: 20px;width: 100%;height: 900px;justify-content: center;align-items: center;position: relative;display: flex;">
			<div style="width: 800px;background: #fff;position: absolute;text-align: center;padding: 20px;">
				<p>
				    <img src="https://image.ipaiban.com/upload-ueditor-image-20221110-1668060349072048965.png" title="https://image.ipaiban.com/upload-ueditor-image-20221110-1668060349072048965.png" alt="image.png"/>
				</p>
				<table cellpadding="0" cellspacing="0" width="100%" o-width="762" r-scale="0.8181799586819121">
				    <tbody>
				        <tr class="firstRow">
				            <td style="font-family: Arial, -apple-system, BlinkMacSystemFont, &quot;PingFang SC&quot;, &quot;Microsoft YaHei&quot;, sans-serif; font-weight: bold; color: rgb(51, 51, 51); text-transform: uppercase; line-height: 20px; padding-bottom: 15px;" class="c-darkmode_purec_51-51-51-100_to_39-39-39-100">
				                we appreciate your interest in Chip 1 Exchange.
				            </td>
				        </tr>
				        <tr>
				            <td style="font-family: Arial, -apple-system, BlinkMacSystemFont, &quot;PingFang SC&quot;, &quot;Microsoft YaHei&quot;, sans-serif; font-size: 14px; color: rgb(51, 51, 51); line-height: 20px; padding-bottom: 15px;" class="c-darkmode_purec_51-51-51-100_to_39-39-39-100">
				                Dear Wei Qin,
				            </td>
				        </tr>
				        <tr>
				            <td style="font-family: Arial, -apple-system, BlinkMacSystemFont, &quot;PingFang SC&quot;, &quot;Microsoft YaHei&quot;, sans-serif; font-size: 14px; color: rgb(136, 136, 136); line-height: 24px; padding-bottom: 20px;" class="c-darkmode_purec_136-136-136-100_to_102-102-102-100">
				                Thankyou for your time while we process your request you may want to search more with us. We will get back to you soon meanwhile look at the details submitted with us.
				            </td>
				        </tr>
				    </tbody>
				</table>
				<table align="center" interlaced="disabled" data-sort="sortDisabled" width="100%">
				    <tbody>
				        <tr class="firstRow">
				            <td valign="middle" style="word-break: break-all; background-color: rgb(250, 250, 250); border-width: 1px; border-style: solid; border-color: #fff;padding: 4px;" align="left" width="124">
				                <span style="font-size: 12px;"><strong>Your Name</strong></span>
				            </td>
				            <td valign="middle" style="background-color: rgb(250, 250, 250); border-width: 1px; border-style: solid; word-break: break-all; border-color: #fff;padding: 4px;" align="left" width="140">
				                <span style="font-size: 12px;"><strong>Part Number</strong></span>
				            </td>
				            <td valign="middle" style="background-color: rgb(250, 250, 250); border-width: 1px; border-style: solid; word-break: break-all; border-color: #fff;padding: 4px;" align="left" width="104">
				                <span style="font-size: 12px;"><strong>Quantity</strong></span>
				            </td>
				            <td valign="middle" style="background-color: rgb(250, 250, 250); border-width: 1px; border-style: solid; word-break: break-all; border-color: #fff;padding: 4px;" align="left" rowspan="1" colspan="2">
				                <span style="font-size: 12px;"><strong>Alternative Part Number</strong></span>
				            </td>
				        </tr>
				        <tr>
				            <td valign="top" style="word-break: break-all; border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(235, 235, 235);" align="left" width="140">
				                <span style="font-size: 12px; color: rgb(127, 127, 127);"> ${e.name} </span>
				            </td>
				            <td valign="top" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(235, 235, 235);" align="left" width="104">
				                <span style="font-size: 12px; color: rgb(127, 127, 127);">${e.model}</span>
				            </td>
				            <td valign="top" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(235, 235, 235);" align="left" width="124">
				                <span style="font-size: 12px; color: rgb(127, 127, 127);">${e.quantity}</span>
				            </td>
				            <td valign="top" style="border-width: 1px; border-style: solid; word-break: break-all; border-color: #fff;padding: 4px; background-color: rgb(235, 235, 235);" rowspan="1" colspan="2">
								<span style="font-size: 12px; color: rgb(127, 127, 127);">${e.brand}</span>
							</td>
				        </tr>
				        <tr>
				            <td valign="top" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(250, 250, 250);" rowspan="1" colspan="2" align="left">
				                <strong><span style="font-size: 12px;">Email</span></strong>
				            </td>
				            <td valign="top" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(250, 250, 250);" rowspan="1" colspan="1" width="87" align="left">
				                <strong><span style="font-size: 12px;">Phone</span></strong>
				            </td>
				            <td rowspan="1" valign="top"  align="left" width="87" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(250, 250, 250);">
				                <strong><span style="font-size: 12px;">Country</span></strong>
				            </td>
				            <td rowspan="1" valign="top"  align="left" width="87" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(250, 250, 250);">
				                <strong><span style="font-size: 12px;">Company</span></strong>
				            </td>
				        </tr>
				        <tr>
				            <td valign="top" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(235, 235, 235);" rowspan="1" colspan="2" align="left">
				                <span style="font-size: 12px; color: rgb(127, 127, 127);"><a href="${'mailto:' + e.emails}">${e.emails}</a></span><br/>
				            </td>
				            <td valign="top" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(235, 235, 235);" width="104" align="left">
				                <span style="font-size: 12px; color: rgb(127, 127, 127);">${e.phone}</span>
				            </td>
				            <td valign="top" align="left" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(235, 235, 235);" rowspan="1" colspan="1" width="70">
				                <span style="font-size: 12px; color: rgb(127, 127, 127);">${e.country}</span><br/>
				            </td>
				            <td rowspan="1" valign="top" align="left" width="70" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(235, 235, 235);">
				                <span style="font-size: 12px; color: rgb(127, 127, 127);">${e.company}</span>
				            </td>
				        </tr>
						<tr>
						    <td valign="top" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(250, 250, 250);" rowspan="1" colspan="2" align="left">
						        <strong><span style="font-size: 12px;">Model</span></strong>
						    </td>
						    <td rowspan="1" colspan="2" valign="top"  align="left" width="87" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(250, 250, 250);">
						        <strong><span style="font-size: 12px;">quantity</span></strong>
						    </td>
						    <td rowspan="1" valign="top"  align="left" width="87" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(250, 250, 250);">
						        <strong><span style="font-size: 12px;">Batch</span></strong>
						    </td>
						</tr>
						<tr>
						    <td valign="top" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(235, 235, 235);" rowspan="1" colspan="2" align="left">
						        <span style="font-size: 12px; color: rgb(127, 127, 127);">${e.model}</span><br/>
						    </td>
						    <td valign="top" align="left" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(235, 235, 235);" rowspan="1" colspan="2" width="87">
						        <span style="font-size: 12px; color: rgb(127, 127, 127);">${e.quantity}</span><br/>
						    </td>
						    <td rowspan="1" valign="top" align="left" width="87" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(235, 235, 235);">
						        <span style="font-size: 12px; color: rgb(127, 127, 127);">${e.batch}</span>
						    </td>
						</tr>
						<tr>
						    <td valign="top" align="left" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(250, 250, 250);" rowspan="1" colspan="5">
						        <strong><span style="font-size: 12px;">Leaving a message</span></strong>
						    </td>
						</tr>
						<tr>
						    <td valign="top" align="left" colspan="5" rowspan="1" style="border-left-color: rgb(242, 242, 242); border-left-width: 1px; border-top-color: rgb(242, 242, 242); border-top-width: 1px; background-color: rgb(235, 235, 235);padding: 4px;">
						        <span style="font-size: 12px;">${e.leavingMessage}</span>
						    </td>
						</tr>
				        <tr>
				            <td valign="top" align="left" style="border-width: 1px; border-style: solid; border-color: #fff;padding: 4px; background-color: rgb(250, 250, 250);" rowspan="1" colspan="5">
				                <strong><span style="font-size: 12px;">Description</span></strong>
				            </td>
				        </tr>
				        <tr>
				            <td valign="top" align="left" colspan="5" rowspan="1" style="border-left-color: rgb(242, 242, 242); border-left-width: 1px; border-top-color: rgb(242, 242, 242); border-top-width: 1px; background-color: rgb(235, 235, 235);padding: 4px;">
				                <span style="font-size: 12px;">Dear Sir/Madam, 
				Please kindly quote for FSX017X
				Kind regards,
				Wei</span><a href="mailto:wei.qin@xxintek.com"><span style="font-size: 12px;">wei.qin@xxin</span>
				                <wbr/><span style="font-size: 12px;">tek.com</span></a><span style="font-size: 12px;">+86 18511380443</span>
				            </td>
				        </tr>
				    </tbody>
				</table>
				<p style="text-align: center;padding: 10px;">
				    <span style="font-size: 12px;">Email us: </span><a href="mailto:marketing@chip1.com?Subject=Size%20Matters" target="_blank" style="font-size: 12px; text-decoration: underline;"><span style="font-size: 12px;">xxintektest1@163.com</span></a>
				</p>
				<p style="text-align: center;padding: 10px;">
				    <span style="font-size: 12px;">USA: </span><a style="font-family: Arial, -apple-system, BlinkMacSystemFont, &quot;PingFang SC&quot;, &quot;Microsoft YaHei&quot;, sans-serif; color: rgb(117, 117, 117); line-height: 18px; font-size: 12px; text-decoration: underline;" class=" c-darkmode_purec_117-117-117-100_to_88-88-88-100"><span style="font-size: 12px;">+1 469 998 2227</span></a><span style="font-size: 12px;"> | Germany: </span><a style="font-family: Arial, -apple-system, BlinkMacSystemFont, &quot;PingFang SC&quot;, &quot;Microsoft YaHei&quot;, sans-serif; color: rgb(117, 117, 117); line-height: 18px; font-size: 12px; text-decoration: underline;" class=" c-darkmode_purec_117-117-117-100_to_88-88-88-100"><span style="font-size: 12px;">+49 (0) 6102-8169-0</span></a><span style="font-size: 12px;"> | Asia: </span><a style="font-family: Arial, -apple-system, BlinkMacSystemFont, &quot;PingFang SC&quot;, &quot;Microsoft YaHei&quot;, sans-serif; color: rgb(117, 117, 117); line-height: 18px; font-size: 12px; text-decoration: underline;" class=" c-darkmode_purec_117-117-117-100_to_88-88-88-100"><span style="font-size: 12px;">+86 755 8364 7396</span></a><span style="font-size: 12px;"> |</span>
				</p>
				<p style="text-align: center;padding: 10px;">
				    <span style="font-size: 12px;">Mexico: <a style="font-size: 12px; font-family: Arial, -apple-system, BlinkMacSystemFont, &quot;PingFang SC&quot;, &quot;Microsoft YaHei&quot;, sans-serif; color: rgb(117, 117, 117); line-height: 18px;" class=" c-darkmode_purec_117-117-117-100_to_88-88-88-100">+52 551 168 9735</a> | Brazil: <a style="font-size: 12px; font-family: Arial, -apple-system, BlinkMacSystemFont, &quot;PingFang SC&quot;, &quot;Microsoft YaHei&quot;, sans-serif; color: rgb(117, 117, 117); line-height: 18px;" class=" c-darkmode_purec_117-117-117-100_to_88-88-88-100">+55 11 3958 7992</a></span>
				</p>
				<p style="text-align: center;padding: 10px;">
				    <span style="font-size: 12px;"><a style="font-size: 12px; font-family: Arial, -apple-system, BlinkMacSystemFont, &quot;PingFang SC&quot;, &quot;Microsoft YaHei&quot;, sans-serif; color: rgb(117, 117, 117); line-height: 18px;" class=" c-darkmode_purec_117-117-117-100_to_88-88-88-100">701 Eight Twenty Blvd, Fort Worth, TX 76106</a></span>
				</p>
				<p style="text-align: center;padding: 10px;width: 100%;background-color: rgb(0, 0, 0);">
				    <span style="font-size: 12px;  color: rgb(255, 255, 255);"><a style="font-size: 12px; font-family: Arial, -apple-system, BlinkMacSystemFont, &quot;PingFang SC&quot;, &quot;Microsoft YaHei&quot;, sans-serif; color: rgb(117, 117, 117); line-height: 18px;" class=" c-darkmode_purec_117-117-117-100_to_88-88-88-100">© 2021, All Rights Reserved</a></span>
				</p>
			</div>
		</div>`

	return html
}

module.exports={
	emailHtml
}