import request from '@/utils/request'

//发送邮件
export function sendmail(data){
	return request({
	  url: '/common/sendmail',
	  method: 'post',
	  data:data
	})
}

//点击日志接口
export function saveLog(data){
	return request({
	  url: '/common/saveLog',
	  method: 'post',
	  data:data
	})
}
