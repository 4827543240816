<template>
	<div>
		<div class="right">
			<div class="ri_top" @click="upTop">
				<!-- <a-icon type="up" class="ri_to_icon" /> -->
				<i class="el-icon-arrow-up"></i>
			</div>
			<div class="ri_text">
				<el-tooltip placement="left">
					<div slot="content">
						<div class="ri_qq">
							<div>{{$t('right.qq.text1')}}</div>
							<div style="padding-top: 10px;padding-bottom: 10px;">{{$t('right.qq.text2')}}</div>
							<el-link href="https://wpa.qq.com/msgrd?v=3&uin=2853903806&site=qqq&menu=yes"
								target="_blank">
								<c-button type="danger" size="small"
									@click="qqClick">{{$t('right.phone.text')}}</c-button>
							</el-link>
						</div>
					</div>
					<el-image class="ri_tx_img2" :src="require('@/assets/img/QQ.png')" fit="cover"></el-image>
				</el-tooltip>
			</div>
			<div class="ri_text">
				<el-tooltip placement="left">
					<div slot="content" @mouseleave="handleMouseLeave(1)">
						<div style="padding-top: 10px;padding-bottom: 10px;">{{$t('right.qq.text1')}}：</div>
						<div style="padding-top: 10px;padding-bottom: 10px;">+86 0755-82056199</div>
					</div>
					<el-image class="ri_tx_img2" :src="require('@/assets/img/phone1.png')" fit="cover"></el-image>
				</el-tooltip>
			</div>
			<div class="ri_text">
				<el-tooltip placement="left">
					<div slot="content" @mouseleave="handleMouseLeave(2)">
						<div style="padding-bottom: 10px;width: 103px;">{{$t('right.opinion.text7')}}</div>
						<el-image class="ri_tx_img" :src="require('@/assets/img/enterpriseQRcode2.jpg')"
							fit="cover"></el-image>
					</div>
					<el-image class="ri_tx_img2" :src="require('@/assets/img/weixin.png')" fit="cover"></el-image>
				</el-tooltip>
			</div>
			<div class="ri_text">
				<el-tooltip placement="left">
					<div slot="content" @mouseleave="handleMouseLeave(3)">
						<div style="padding-bottom: 10px;width: 103px;">{{$t('right.opinion.text8')}}</div>
						<el-image class="ri_tx_img" :src="require('@/assets/img/enterpriseQRcode.jpg')"
							fit="cover"></el-image>
					</div>
					<el-image class="ri_tx_img2" :src="require('@/assets/img/QRcode.png')" fit="cover"></el-image>
				</el-tooltip>
			</div>
			<div class="ri_text">
				<el-tooltip placement="left">
					<div slot="content">
						<div style="width: 300px;margin: 10px;">
							<div style="padding-bottom: 10px;">{{$t('right.opinion.title')}}：</div>
						</div>
						<div style="width: 300px;margin: 10px;">
							<div style="padding-bottom: 10px;display: flex;">
								<div style="color: red;">*</div>{{$t('right.opinion.text1')}}
							</div>
							<div style="display: flex;">
								<el-input style="width: 200px;" :placeholder="$t('right.opinion.input1')"
									v-model="form.name" />
								<el-select style="width: 90px;margin-left: 10px;" v-model="nameValue" placeholder="请选择">
									<el-option v-for="item in options" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</div>
						</div>

						<div style="width: 300px;margin: 10px;">
							<div style="padding-bottom: 10px;display: flex;">
								<div style="color: red;">*</div>{{$t('right.opinion.text2')}}
							</div>
							<el-input :placeholder="$t('right.opinion.input2')" v-model="form.company" />
						</div>

						<div style="width: 300px;margin: 10px;">
							<div style="padding-bottom: 10px;display: flex;">
								<div style="color: red;">*</div>{{$t('right.opinion.text3')}}
							</div>
							<el-input :placeholder="$t('right.opinion.input3')" v-model="form.phone" />
						</div>

						<div style="width: 300px;margin: 10px;">
							<div style="padding-bottom: 10px;">{{$t('right.opinion.text6')}}</div>
							<el-input :placeholder="$t('right.opinion.input5')" v-model="form.emails" />
						</div>

						<div style="width: 300px;margin: 10px;">
							<div style="padding-bottom: 10px;display: flex;">
								<div style="color: red;">*</div>{{$t('right.opinion.text4')}}
							</div>
							<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}"
								:placeholder="$t('right.opinion.input4')" v-model="form.leavingMessage"></el-input>
						</div>

						<div style="width: 300px;margin: 20px 10px 10px 10px;">
							<el-button
							  :loading="loading"
							  size="medium"
							  type="danger"
							  style="width:100%;"
							  @click.native.prevent="sendComments"
							>
							  <span v-if="!loading">{{$t('right.opinion.text5')}}</span>
							  <span v-else>{{$t('right.opinion.text9')}}...</span>
							</el-button>
						</div>
					</div>
					<el-image class="ri_tx_img3" :src="require('@/assets/img/feedback.png')" fit="cover"></el-image>
				</el-tooltip>
			</div>
			<div class="ri_bottom" @click="down">
				<!-- <a-icon type="down" class="ri_bo_down" /> -->
				<i class="el-icon-arrow-down"></i>
			</div>
		</div>
	</div>
</template>

<script>
	import CButton from '@/components/CButton/index.vue'
	import {
		scrollTo
	} from '@/utils/scroll-to'
	import axios from 'axios';
	import {
		sendmail,
		saveLog
	} from '@/api/home/index.js'
	import html from '@/utils/htmlEmail'
	export default {
		components: {
			CButton
		},
		data() {
			return {
				form: {
					emails: null, //邮箱
					leavingMessage: null, //留言
					name: null, //姓名
					company: null, //公司名称
					phone: null, //电话号码
					title: null, //标题
					ipaddress: null, //ip地址
					userInfo: null,
				},
				options: [{
					value: '先生',
					label: '先生'
				}, {
					value: '女士',
					label: '女士'
				}],
				nameValue: '先生',
				loading: false,
			}
		},

		created() {
			axios.get('https://api.ipify.org?format=json').then(response => {
				this.ipaddress = response.data.ip;
				// console.log("ipaddress",this.ruleForm)
			}).catch(error => {
				console.log(error);
			});
		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {
			upTop: function() {
				scrollTo(0, 800)
			},

			down: function() {
				scrollTo(document.body.scrollHeight, 800)
			},

			sendComments: function() {
				this.loading=true
				if(this.form.name==null){
					this.$message.error("请输入姓名")
					this.loading=false
				}else if(this.form.phone==null){
					this.$message.error("请输入电话号码")
					this.loading=false
				}else if (!(/^1[3456789]\d{9}$/.test(this.form.phone))) {
					this.$message.error("请输入正确的电话号码")
					this.loading=false
				}else if(this.form.company==null){
					this.$message.error("请输入公司")
					this.loading=false
				}else if(this.form.leavingMessage==null){
					this.$message.error("请输入留言")
					this.loading=false
				}else{
					let data = {
						actiondo:"点击发送留言",
						ipaddress:this.ipaddress,
						uid:this.userInfo!==null ? null : this.userInfo.id
					}
					saveLog(data).then(res=>{
						this.form.title='姓名：'+this.form.name + this.nameValue + ',留言人电话：' + this.form.phone+'公司名称：' + this.form.company + ',留言人邮箱地址：' + this.form.emails
						let arr={
							subject: this.form.title,
							content: html.emailHtml(this.form),
							type: 2
						}
						sendmail(arr).then(res => {
							if (res.code == 200) {
								this.open=false
								this.loading=false
								this.$message({
									message: '邮件发送成功！',
									type: 'success'
								});
								this.$alert('您的意见我们已经收到，我们的工作人员会与您联系，衷心谢谢您的反馈！', '意见反馈', {
								          confirmButtonText: '确定',
								        });
							}else{
								this.loading=false
								this.$message.error('邮件发送失败！');
							}
						})
					})
				}
			},

			handleMouseLeave(e) {
				let data = {
					actiondo: e == 1 ? '查看手机号' : e == 2 ? '小程序' : '公众号',
					ipaddress: this.ipaddress,
					uid: this.userInfo !== null ? null : this.userInfo.id
				}
				saveLog(data).then(res => {

				})
			},

			qqClick: function(e) {
				let data = {
					actiondo: "点击qq",
					ipaddress: this.ipaddress,
					uid: this.userInfo !== null ? null : this.userInfo.id
				}
				saveLog(data).then(res => {

				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.el-icon-arrow-down {
		color: #fff;
		font-size: 20px;
	}

	.el-icon-arrow-up {
		color: #fff;
		font-size: 20px;
	}

	@media only screen and (min-width:1920px) {
		.right {
			position: fixed;
			bottom: 150px;
			right: 0;
			z-index: 999;

			.ri_top {
				background: #000;
				opacity: .9;
				border-top-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_to_icon {
					color: #fff;
					font-size: 24px;
				}
			}

			.ri_text {
				background: #000;
				opacity: .9;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_qq {
					color: #fff;
					padding: 10px;
					width: 150px;
				}

				.ri_tx_img2 {
					width: 40px;
					height: 40px;
				}

				.ri_tx_img3 {
					width: 35px;
					height: 35px;
				}

				.ri_icon {
					color: #fff;
					font-size: 24px;
				}
			}

			.ri_bottom {
				background: #000;
				opacity: .9;
				border-bottom-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_bo_down {
					color: #fff;
					font-size: 24px;
				}
			}

			.ri_top:hover {
				opacity: .8;
			}

			.ri_text:hover {
				opacity: .8;
			}

			.ri_bottom:hover {
				opacity: .8;
			}

		}

		.ri_tx_img {
			width: 100px;
			height: 100px;
		}
	}

	@media only screen and (max-width:1920px) {
		.right {
			position: fixed;
			bottom: 150px;
			right: 0;
			z-index: 999;

			.ri_top {
				background: #000;
				opacity: .9;
				border-top-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_to_icon {
					color: #fff;
					font-size: 24px;
				}
			}

			.ri_text {
				background: #000;
				opacity: .9;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_qq {
					color: #fff;
					padding: 10px;
					width: 150px;
				}

				.ri_tx_img2 {
					width: 40px;
					height: 40px;
				}

				.ri_tx_img3 {
					width: 35px;
					height: 35px;
				}

				.ri_icon {
					color: #fff;
					font-size: 24px;
				}
			}

			.ri_bottom {
				background: #000;
				opacity: .9;
				border-bottom-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_bo_down {
					color: #fff;
					font-size: 24px;
				}
			}

			.ri_top:hover {
				opacity: .8;
			}

			.ri_text:hover {
				opacity: .8;
			}

			.ri_bottom:hover {
				opacity: .8;
			}

		}

		.ri_tx_img {
			width: 100px;
			height: 100px;
		}
	}

	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.right {
			position: fixed;
			bottom: 150px;
			right: 0;
			z-index: 999;

			.ri_top {
				background: #000;
				opacity: .9;
				border-top-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_to_icon {
					color: #fff;
					font-size: 24px;
				}
			}

			.ri_text {
				background: #000;
				opacity: .9;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_qq {
					color: #fff;
					padding: 10px;
					width: 150px;
				}

				.ri_tx_img2 {
					width: 40px;
					height: 40px;
				}

				.ri_tx_img3 {
					width: 35px;
					height: 35px;
				}

				.ri_icon {
					color: #fff;
					font-size: 24px;
				}
			}

			.ri_bottom {
				background: #000;
				opacity: .9;
				border-bottom-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_bo_down {
					color: #fff;
					font-size: 24px;
				}
			}

			.ri_top:hover {
				opacity: .8;
			}

			.ri_text:hover {
				opacity: .8;
			}

			.ri_bottom:hover {
				opacity: .8;
			}

		}

		.ri_tx_img {
			width: 100px;
			height: 100px;
		}
	}

	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.right {
			position: fixed;
			bottom: 150px;
			right: 0;
			z-index: 999;

			.ri_top {
				background: #000;
				opacity: .9;
				border-top-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_to_icon {
					color: #fff;
					font-size: 16px;
				}
			}

			.ri_text {
				background: #000;
				opacity: .9;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_qq {
					color: #fff;
					padding: 10px;
					width: 150px;
				}

				.ri_tx_img2 {
					width: 30px;
					height: 30px;
				}

				.ri_tx_img3 {
					width: 25px;
					height: 25px;
				}

				.ri_icon {
					color: #fff;
					font-size: 16px;
				}
			}

			.ri_bottom {
				background: #000;
				opacity: .9;
				border-bottom-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_bo_down {
					color: #fff;
					font-size: 16px;
				}
			}

			.ri_top:hover {
				opacity: .8;
			}

			.ri_text:hover {
				opacity: .8;
			}

			.ri_bottom:hover {
				opacity: .8;
			}

		}

		.ri_tx_img {
			width: 100px;
			height: 100px;
		}
	}

	@media only screen and (max-width:1199px) and (min-width:768px) {
		.right {
			position: fixed;
			bottom: 150px;
			right: 0;
			z-index: 999;

			.ri_top {
				background: #000;
				opacity: .9;
				border-top-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_to_icon {
					color: #fff;
					font-size: 16px;
				}
			}

			.ri_text {
				background: #000;
				opacity: .9;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_qq {
					color: #fff;
					padding: 10px;
					width: 150px;
				}

				.ri_tx_img2 {
					width: 30px;
					height: 30px;
				}

				.ri_tx_img3 {
					width: 25px;
					height: 25px;
				}

				.ri_icon {
					color: #fff;
					font-size: 16px;
				}
			}

			.ri_bottom {
				background: #000;
				opacity: .9;
				border-bottom-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_bo_down {
					color: #fff;
					font-size: 16px;
				}
			}

			.ri_top:hover {
				opacity: .8;
			}

			.ri_text:hover {
				opacity: .8;
			}

			.ri_bottom:hover {
				opacity: .8;
			}

		}

		.ri_tx_img {
			width: 100px;
			height: 100px;
		}
	}

	@media only screen and (max-width:767px) {
		.right {
			position: fixed;
			bottom: 150px;
			right: 0;
			z-index: 999;

			.ri_top {
				background: #000;
				opacity: .9;
				border-top-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_to_icon {
					color: #fff;
					font-size: 12px;
				}
			}

			.ri_text {
				background: #000;
				opacity: .9;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_qq {
					color: #fff;
					padding: 10px;
					width: 100px;
				}

				.ri_tx_img2 {
					width: 20px;
					height: 20px;
				}

				.ri_tx_img3 {
					width: 20px;
					height: 20px;
				}

				.ri_icon {
					color: #fff;
					font-size: 12px;
				}
			}

			.ri_bottom {
				background: #000;
				opacity: .9;
				border-bottom-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;

				.ri_bo_down {
					color: #fff;
					font-size: 12px;
				}
			}

			.ri_top:hover {
				opacity: .8;
			}

			.ri_text:hover {
				opacity: .8;
			}

			.ri_bottom:hover {
				opacity: .8;
			}

		}

		.ri_tx_img {
			width: 100px;
			height: 100px;
		}
	}
</style>